import { EPlatformName, ETelematicsMessagesKeys } from "../types";

export const HELP_URL_BY_PLATFORM = {
  [EPlatformName.GEOTAB]: ETelematicsMessagesKeys.GEOTAB_INSTRUCTIONS_URL,
  [EPlatformName.TRIMBLE]: ETelematicsMessagesKeys.TRIMBLE_INSTRUCTIONS_URL,
  [EPlatformName.WEBFLEET]: ETelematicsMessagesKeys.WEBFLEET_INSTRUCTIONS_URL,
  [EPlatformName.WIALON]: ETelematicsMessagesKeys.WIALON_INSTRUCTIONS_URL,
  [EPlatformName.GPS_INSIGHT]:
    ETelematicsMessagesKeys.GPS_INSIGHT_INSTRUCTIONS_URL,
  [EPlatformName.MOTIVE]: ETelematicsMessagesKeys.MOTIVE_INSTRUCTIONS_URL,
  [EPlatformName.SAMSARA]: ETelematicsMessagesKeys.SAMSARA_INSTRUCTIONS_URL,
  [EPlatformName.VERIZON]: ETelematicsMessagesKeys.VERIZON_INSTRUCTIONS_URL,
  [EPlatformName.MIX_TELEMATICS]:
    ETelematicsMessagesKeys.MIXTELEMATICS_INSTRUCTIONS_URL,
  [EPlatformName.EROAD]: ETelematicsMessagesKeys.EROAD_INSTRUCTIONS_URL,
  [EPlatformName.FLEET_COMPLETE]:
    ETelematicsMessagesKeys.FLEET_COMPLETE_INSTRUCTIONS_URL,
  [EPlatformName.GPS_TAB]: ETelematicsMessagesKeys.GPS_TAB_INSTRUCTIONS_URL,
  [EPlatformName.LYTX]: ETelematicsMessagesKeys.LYTX_INSTRUCTIONS_URL,
  [EPlatformName.OMNITRACS]: ETelematicsMessagesKeys.OMNITRACS_INSTRUCTIONS_URL,
  [EPlatformName.ISAAC]: ETelematicsMessagesKeys.ISAAC_INSTRUCTIONS_URL,
  [EPlatformName.NETRADYNE]: ETelematicsMessagesKeys.NETRADYNE_INSTRUCTIONS_URL,
  [EPlatformName.NEXTRAQ]: ETelematicsMessagesKeys.NEXTRAQ_INSTRUCTIONS_URL,
  [EPlatformName.ONE_STEP_GPS]:
    ETelematicsMessagesKeys.ONE_STEP_GPS_INSTRUCTIONS_URL,
  [EPlatformName.FLEET_LOCATE_SPIREON]:
    ETelematicsMessagesKeys.FLEET_LOCATE_SPIREON_INSTRUCTIONS_URL,
  [EPlatformName.XRS]: ETelematicsMessagesKeys.XRS_INSTRUCTIONS_URL,
  [EPlatformName.ZONAR_SYSTEMS]:
    ETelematicsMessagesKeys.ZONAR_SYSTEMS_INSTRUCTIONS_URL,
  [EPlatformName.ORBCOMM_ELD]:
    ETelematicsMessagesKeys.ORBCOMM_ELD_INSTRUCTIONS_URL,
  [EPlatformName.ORBCOMM_BLUE_TREE]:
    ETelematicsMessagesKeys.ORBCOMM_BLUE_TREE_INSTRUCTIONS_URL,
  [EPlatformName.VISION_TRACK]:
    ETelematicsMessagesKeys.VISION_TRACK_INSTRUCTIONS_URL,
  [EPlatformName.GORILLA_SAFETY]:
    ETelematicsMessagesKeys.GORILLA_SAFETY_INSTRUCTIONS_URL,
  [EPlatformName.WAYLENS]: ETelematicsMessagesKeys.WAYLENS_INSTRUCTIONS_URL,
  [EPlatformName.REQUEST]: ETelematicsMessagesKeys.REQUEST_INSTRUCTIONS_URL,
  [EPlatformName.INSIGHT_MOBILE_DATA]:
    ETelematicsMessagesKeys.INSIGHT_MOBILE_DATA_INSTRUCTIONS_URL,
  [EPlatformName.RHINO]: ETelematicsMessagesKeys.RHINO_INSTRUCTIONS_URL,
  [EPlatformName.MONARCH_GPS]:
    ETelematicsMessagesKeys.MONARCH_GPS_INSTRUCTIONS_URL,
  [EPlatformName.TOUCHTEL_GPS]:
    ETelematicsMessagesKeys.TOUCHTEL_GPS_INSTRUCTIONS_URL,
  [EPlatformName.TRUCKSPY]: ETelematicsMessagesKeys.TRUCKSPY_INSTRUCTIONS_URL,
  [EPlatformName.GPS_COMMANDER]:
    ETelematicsMessagesKeys.GPS_COMMANDER_INSTRUCTIONS_URL,
  [EPlatformName.ATNT_FLEET_COMPLETE]:
    ETelematicsMessagesKeys.ATNT_FLEET_COMPLETE_INSTRUCTIONS_URL,
  [EPlatformName.FLEET_UP]: ETelematicsMessagesKeys.FLEET_UP_INSTRUCTIONS_URL,
  [EPlatformName.ACTSOFT_ENCORE]:
    ETelematicsMessagesKeys.ACTSOFT_ENCORE_INSTRUCTIONS_URL,
  [EPlatformName.ADVANTAGE_ASSET_TRACKING]:
    ETelematicsMessagesKeys.ADVANTAGE_ASSET_TRACKING_INSTRUCTIONS_URL,
  [EPlatformName.ARGOS_CONNECTED_SOLUTIONS]:
    ETelematicsMessagesKeys.ARGOS_CONNECTED_SOLUTIONS_INSTRUCTIONS_URL,
  [EPlatformName.ARI_FLEET]: ETelematicsMessagesKeys.ARI_FLEET_INSTRUCTIONS_URL,
  [EPlatformName.ASSURED_TELEMATICS]:
    ETelematicsMessagesKeys.ASSURED_TELEMATICS_INSTRUCTIONS_URL,
  [EPlatformName.ATNT_FLEET]:
    ETelematicsMessagesKeys.ATNT_FLEET_INSTRUCTIONS_URL,
  [EPlatformName.ATTRIX]: ETelematicsMessagesKeys.ATTRIX_INSTRUCTIONS_URL,
  [EPlatformName.BADGER_FLEET_SOLUTIONS]:
    ETelematicsMessagesKeys.BADGER_FLEET_SOLUTIONS_INSTRUCTIONS_URL,
  [EPlatformName.BAR_CODING_CANADA]:
    ETelematicsMessagesKeys.BAR_CODING_CANADA_INSTRUCTIONS_URL,
  [EPlatformName.BLUEARROW]: ETelematicsMessagesKeys.BLUEARROW_INSTRUCTIONS_URL,
  [EPlatformName.CARRIERHQ]: ETelematicsMessagesKeys.CARRIERHQ_INSTRUCTIONS_URL,
  [EPlatformName.ENVUE_TELEMATICS]:
    ETelematicsMessagesKeys.ENVUE_TELEMATICS_INSTRUCTIONS_URL,
  [EPlatformName.FLEET_NAV_SYSTEMS]:
    ETelematicsMessagesKeys.FLEET_NAV_SYSTEMS_INSTRUCTIONS_URL,
  [EPlatformName.FLEET_PROFIT_CENTER]:
    ETelematicsMessagesKeys.FLEET_PROFIT_CENTER_INSTRUCTIONS_URL,
  [EPlatformName.FLEETBOSS_GPS]:
    ETelematicsMessagesKeys.FLEETBOSS_GPS_INSTRUCTIONS_URL,
  [EPlatformName.FLEETHOSTER]:
    ETelematicsMessagesKeys.FLEETHOSTER_INSTRUCTIONS_URL,
  [EPlatformName.FLEETISTICS]:
    ETelematicsMessagesKeys.FLEETISTICS_INSTRUCTIONS_URL,
  [EPlatformName.FLEETLOCATE_GEOTAB]:
    ETelematicsMessagesKeys.FLEETLOCATE_GEOTAB_INSTRUCTIONS_URL,
  [EPlatformName.FLEETMASTER]:
    ETelematicsMessagesKeys.FLEETMASTER_INSTRUCTIONS_URL,
  [EPlatformName.FLEXPORT]: ETelematicsMessagesKeys.FLEXPORT_INSTRUCTIONS_URL,
  [EPlatformName.GEOFORCE]: ETelematicsMessagesKeys.GEOFORCE_INSTRUCTIONS_URL,
  [EPlatformName.GLOSTONE]: ETelematicsMessagesKeys.GLOSTONE_INSTRUCTIONS_URL,
  [EPlatformName.GOFLEET]: ETelematicsMessagesKeys.GOFLEET_INSTRUCTIONS_URL,
  [EPlatformName.GOGPS]: ETelematicsMessagesKeys.GOGPS_INSTRUCTIONS_URL,
  [EPlatformName.GPS_FLEET_FINDER]:
    ETelematicsMessagesKeys.GPS_FLEET_FINDER_INSTRUCTIONS_URL,
  [EPlatformName.GPS_SOLUTIONS]:
    ETelematicsMessagesKeys.GPS_SOLUTIONS_INSTRUCTIONS_URL,
  [EPlatformName.GPS_TRACKING_CANADA]:
    ETelematicsMessagesKeys.GPS_TRACKING_CANADA_INSTRUCTIONS_URL,
  [EPlatformName.GRAYBOX_SOLUTIONS]:
    ETelematicsMessagesKeys.GRAYBOX_SOLUTIONS_INSTRUCTIONS_URL,
  [EPlatformName.GRIDLINE]: ETelematicsMessagesKeys.GRIDLINE_INSTRUCTIONS_URL,
  [EPlatformName.HIGHPOINT_GPS]:
    ETelematicsMessagesKeys.HIGHPOINT_GPS_INSTRUCTIONS_URL,
  [EPlatformName.IOTAB]: ETelematicsMessagesKeys.IOTAB_INSTRUCTIONS_URL,
  [EPlatformName.LYNX]: ETelematicsMessagesKeys.LYNX_INSTRUCTIONS_URL,
  [EPlatformName.ONTRAK_SOLUTIONS]:
    ETelematicsMessagesKeys.ONTRAK_SOLUTIONS_INSTRUCTIONS_URL,
  [EPlatformName.PENSKE]: ETelematicsMessagesKeys.PENSKE_INSTRUCTIONS_URL,
  [EPlatformName.PREPASS_ELD]:
    ETelematicsMessagesKeys.PREPASS_ELD_INSTRUCTIONS_URL,
  [EPlatformName.QUALITY_GPS]:
    ETelematicsMessagesKeys.QUALITY_GPS_INSTRUCTIONS_URL,
  [EPlatformName.RMJ_TECHNOLOGIES]:
    ETelematicsMessagesKeys.RMJ_TECHNOLOGIES_INSTRUCTIONS_URL,
  [EPlatformName.RUSH_ENTERPRISES]:
    ETelematicsMessagesKeys.RUSH_ENTERPRISES_INSTRUCTIONS_URL,
  [EPlatformName.RYDER]: ETelematicsMessagesKeys.RYDER_INSTRUCTIONS_URL,
  [EPlatformName.SAFETY_VISION]:
    ETelematicsMessagesKeys.SAFETY_VISION_INSTRUCTIONS_URL,
  [EPlatformName.SHELL_TELEMATICS]:
    ETelematicsMessagesKeys.SHELL_TELEMATICS_INSTRUCTIONS_URL,
  [EPlatformName.SURECAM]: ETelematicsMessagesKeys.SURECAM_INSTRUCTIONS_URL,
  [EPlatformName.T_MOBILE]: ETelematicsMessagesKeys.T_MOBILE_INSTRUCTIONS_URL,
  [EPlatformName.TRANSFLO]: ETelematicsMessagesKeys.TRANSFLO_INSTRUCTIONS_URL,
  [EPlatformName.TRAXXISGPS]:
    ETelematicsMessagesKeys.TRAXXISGPS_INSTRUCTIONS_URL,
  [EPlatformName.TRUPATH_SYSTEMS]:
    ETelematicsMessagesKeys.TRUPATH_SYSTEMS_INSTRUCTIONS_URL,
  [EPlatformName.VERTRAX]: ETelematicsMessagesKeys.VERTRAX_INSTRUCTIONS_URL,
  [EPlatformName.ZENDUIT]: ETelematicsMessagesKeys.ZENDUIT_INSTRUCTIONS_URL,
  [EPlatformName.HOLMAN]: ETelematicsMessagesKeys.HOLMAN_INSTRUCTIONS_URL,
  [EPlatformName.ENTERPRISE_FLEET_MANAGEMENT]:
    ETelematicsMessagesKeys.ENTERPRISE_FLEET_MANAGEMENT_INSTRUCTIONS_URL,
  [EPlatformName.LINXUP]: ETelematicsMessagesKeys.LINXUP_INSTRUCTIONS_URL,
  [EPlatformName.FLEETSHARP]:
    ETelematicsMessagesKeys.FLEETSHARP_INSTRUCTIONS_URL,
  [EPlatformName.LION_EIGHT]:
    ETelematicsMessagesKeys.LION_EIGHT_INSTRUCTIONS_URL,
  [EPlatformName.ELD_RIDER_PRO]:
    ETelematicsMessagesKeys.ELD_RIDER_PRO_INSTRUCTIONS_URL,
  [EPlatformName.SHELD]: ETelematicsMessagesKeys.SHELD_INSTRUCTIONS_URL,
  [EPlatformName.XELD]: ETelematicsMessagesKeys.XELD_INSTRUCTIONS_URL,
  [EPlatformName.ZELD]: ETelematicsMessagesKeys.ZELD_INSTRUCTIONS_URL,
  [EPlatformName.AZUGA]: ETelematicsMessagesKeys.AZUGA_INSTRUCTIONS_URL,
  [EPlatformName.WORKWAVE]: ETelematicsMessagesKeys.WORKWAVE_INSTRUCTIONS_URL,
  [EPlatformName.TELETRAC_NAVMAN]:
    ETelematicsMessagesKeys.TELETRAC_NAVMAN_INSTRUCTIONS_URL,
};
